import forList from "@/mixin/forList";
import axios from 'axios';
import { Loading } from 'element-ui';

// 生成一个随机数字 默认6位数
function createNumber(count = 6) {
    return Math.floor(Math.random() * (10 ** count))
}

export default {
    name: "giftActivityList",
    mixins: [forList],
    noSaveState: 1,

    data() {
        return {
            editDialog: false,
            actEditData: {
                actName: '',
                remark: '',
                id: '',
                actStartTime: '',
                actEndTime: '',
                file: [],
            },
            editRules: {
                actName: { required: true, message: "请输入活动名称", trigger: "blur" },
                // file: { required: true, message: "未选择文件", trigger: "blur" },
                actStartTime: { required: true, message: "请选择开始日期", trigger: "blur" },
                actEndTime: { required: true, message: "请选择结束日期", trigger: "blur" },
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },
            },

            addDialog: false,
            actData: {
                actName: '',
                actStartTime: '',
                actEndTime: '',
                file: [],
                remark: '',
            },
            rules: {
                actName: { required: true, message: "请输入活动名称", trigger: "blur" },
                file: { required: true, message: "请上传客户表格", trigger: "blur" },
                actStartTime: { required: true, message: "请选择开始日期", trigger: "blur" },
                actEndTime: { required: true, message: "请选择结束日期", trigger: "blur" },
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },
            },
            // giftActivityCustomerTemplate
            // sampleFileUrl: this.$baseURL + "upload/winktemp/custcoupontemp.xlsx",
            sampleFileUrl: this.$baseURL + "upload/winktemp/giftActivityCustomerTemplate.xlsx",



            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                actName: '',
                // searchType: 1,    // 搜索类型
                // searchValue: "",   // 搜索类容
                // searchState: "",   // 状态
                // searchDraw: "",    // 是否已领取
                // startTime: "",  // 开始时间
                // endTime: "",    // 结束时间
            },
            exporting: false,      // 导出中
            // pageFlag: 0,           // 保存一个随机数字，在获取列表数量前随机生成，在请返回时验证是否是最新一次请求

            //#region 选项配置
            searchTypeList: [      // 查询条件
                { value: 1, name: "赠礼活动名称" },
            ],
            drawTypeList: [
                { value: 0, name: "待领取" },
                { value: 1, name: "已领取" }
            ],
            stateList: [           // 状态
                { value: 0, name: "失效" },
                { value: 1, name: "可使用" },
                { value: 2, name: "已使用" },
                { value: 3, name: "已作废" },
                { value: 5, name: "待解锁" }
            ],
            //#endregion
        };
    },

    computed: {
        buttonFreezing() {
            return this.loading || this.operating
        },

        bulkFreezing() {
            return this.buttonFreezing || !this.checkedlist.length
        }
    },

    methods: {
        handleAdd() {
            this.addDialog = true;
        },

        handleClose() {
            this.actData = {
                actName: '',
                actStartTime: '',
                actEndTime: '',
                file: [],
                remark: '',
            }
            this.$refs.issue.clearValidate();
            this.addDialog = false;
        },


        handleExceed() {
            this.$message({
                message: "只能上传一个表格",
                type: "error"
            })
        },
        handleExceed_edit() {
            this.$message({
                message: "只能上传一个表格",
                type: "error"
            })
        },
        handleSelectFile(e) {
            this.actData.file.push(e.raw)
        },
        handleSelectFile_edit(e) {
            this.actEditData.file.push(e.raw)
        },
        handleRemoveFile(e) {
            const index = this.actData.file.indexOf(e);
            this.actData.file.splice(index, 1)
        },
        handleRemoveFile_edit(e) {
            const index = this.actEditData.file.indexOf(e);
            this.actEditData.file.splice(index, 1)
        },
        handleBulkIssue_old() {
            // let loadingRef;
            // this.$refs.issue.validate()
            //     .then(() => {
            //         loadingRef = Loading.service({
            //             target: ".el-dialog"
            //         })
            //         const data = new FormData();
            //         const { cusfile, couponConfId, remark } = this.issueData;
            //         data.append("cusfile", cusfile[0]);
            //         data.append("couponConfId", couponConfId);
            //         data.append("remark", remark);
            //         return axios.post(this.$baseURL + "api/custCoupon/batchGiftCustCoupon", data, {
            //             headers: {
            //                 "Content-Type": "multipart/form-data",
            //                 token: this.$store.state.token
            //             }
            //         })
            //     })
            //     .then(res => {
            //         if (res.data.code === 2000) {

            //             this.$alert(res.data.msg || "发放成功", "批量发放结果");
            //             this.issueData = null;
            //             loadingRef && loadingRef.close();

            //         } else if (res.data?.code === 1003) {
            //             this.$store.commit("clearUserInfo");
            //             this.$message({
            //                 message: "登录过期，请重新登录",
            //                 type: "warning"
            //             });
            //             return Promise.reject(false);

            //         } else return Promise.reject(res.data);
            //     })
            //     .catch(reason => {
            //         console.warn("上传失败", reason);
            //         if (reason !== false) {
            //             this.$message({
            //                 message: reason?.msg || "发放失败",
            //                 type: "error"
            //             })
            //         }
            //         loadingRef && loadingRef.close();
            //     })
        },
        handleBulkIssue() {
            let loadingRef;
            this.$refs.issue.validate()
                .then(() => {
                    loadingRef = Loading.service({
                        target: ".el-dialog"
                    })
                    const data = new FormData();
                    const { file, remark } = this.actData;
                    data.append("actName", this.actData.actName);
                    data.append("actStartTime", this.actData.actStartTime);
                    data.append("actEndTime", this.actData.actEndTime);
                    data.append("file", file[0]);
                    data.append("remark", remark);
                    return axios.post(this.$baseURL + "api/giftActivity/addGiftActivity", data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            token: this.$store.state.token
                        }
                    })
                })
                .then(res => {
                    if (res.data.code === 2000) {
                        if (res.data.data.errNum > 0) {
                            this.$message({
                                message: `存在${res.data.data.errNum}条错误数据，结果已自动导出`,
                                type: "warning"
                            });
                            setTimeout(() => {
                                window.open(this.$baseURL + res.data.data.errPath, "download");
                            }, 2000);
                        } else {
                            this.$message({
                                message: "保存成功",
                                type: "success"
                            })
                        }
                        setTimeout(() => {
                            loadingRef && loadingRef.close();
                            this.handleClose();
                            this.fetchData()
                        }, 500);


                    } else if (res.data?.code === 1003) {
                        this.$store.commit("clearUserInfo");
                        this.$message({
                            message: "登录过期，请重新登录",
                            type: "warning"
                        });
                        return Promise.reject(false);

                    } else return Promise.reject(res.data);
                })
                .catch(reason => {
                    console.warn("上传失败", reason);
                    if (reason !== false) {
                        this.$message({
                            message: reason?.msg || "发放失败",
                            type: "error"
                        })
                    }
                    loadingRef && loadingRef.close();
                })
        },
        handleEditOpen(row) {
            console.log(row);
            this.editDialog = true
            this.actEditData.actName = row.actName
            this.actEditData.remark = row.remark
            this.actEditData.id = row.id
            this.actEditData.actStartTime = row.actStartTime.replace(/\//g, '-')
            this.actEditData.actEndTime = row.actEndTime.replace(/\//g, '-')
            // let formattedDate = dateString.replace(/\//g, '-')
        },
        handleEditClose() {
            this.actEditData = {
                actName: '',
                remark: '',
                id: '',
                actStartTime: '',
                actEndTime: '',
                file: []
            }
            this.editDialog = false;
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);

        },
        handleEditSure() {
            let loadingRef;
            this.$refs.form.validate()
                .then(() => {
                    loadingRef = Loading.service({
                        target: ".editDialog .el-dialog"
                    })
                    // const data = this.actEditData

                    const data = new FormData();
                    const { file, remark } = this.actEditData;
                    console.log(file);
                    data.append("actName", this.actEditData.actName);
                    data.append("actStartTime", this.actEditData.actStartTime);
                    data.append("actEndTime", this.actEditData.actEndTime);
                    data.append("id", this.actEditData.id);

                    if (file.length == 0) {
                        data.append("file", '');
                    } else {
                        data.append("file", file[0]);
                    }
                    data.append("remark", remark);
                    return axios.post(this.$baseURL + "api/giftActivity/editGiftActivity", data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            token: this.$store.state.token
                        }
                    })
                    // return this.$axios({
                    //     url: "api/giftActivity/editGiftActivity",
                    //     method: "post",
                    //     data
                    // })
                })

                .then(res => {
                    console.log(123);
                    if (res.data.code === 2000) {

                        if (res.data.data.errNum > 0) {
                            this.$message({
                                message: `存在${res.data.data.errNum}条错误数据，结果已自动导出`,
                                type: "warning"
                            });
                            setTimeout(() => {
                                window.open(this.$baseURL + res.data.data.errPath, "download");
                            }, 2000);
                        } else {
                            this.$message({
                                message: "保存成功",
                                type: "success"
                            })
                        }
                        setTimeout(() => {
                            loadingRef && loadingRef.close();
                            this.fetchData();
                            this.handleEditClose();
                        }, 500);
                    } else {
                        loadingRef && loadingRef.close();
                        this.$message({
                            message: res.data.msg || "保存失败",
                            type: "error"
                        });
                    }
                })

                .catch(reason => {
                    // this.$refs.form.validate 验证失败的拒绝结果是值 false
                    // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                    reason && this.$message({
                        message: "保存失败",
                        type: "error"
                    });
                    console.warn(reason);
                })

                .then(() => {
                    this.posting = false
                })
        },
        handleDel(row) {
            this.$confirm(`您确定要删除么?`, "温馨提示")
                .then(() => {
                    this.loading = true;
                    return this.$axios({
                        url: "api/giftActivity/deleteGiftActivity",
                        method: "post",
                        data: { id: row.id }
                    })
                })
                .then(res => {
                    this.loading = false
                    if (res.code === 2000) {
                        this.$message({
                            message: "删除成功",
                            type: "success"
                        })
                        this.fetchData();
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.loading = false
                    if (reason !== "cancel") {
                        this.$message({
                            message: reason.msg || "删除失败",
                            type: "error"
                        })
                    }
                })
        },
        toDetail(row) {
            // this.$router.push("/user/giftActivityDetail");
            this.$router.push(`/user/giftActivityList/${row.id}`);
            // this.$router.push(`/user/user/${row.id || 5}/${1}`);
            // this.$router.push(`/user/giftActivityList/${row.id || 5}/${row.id}`);

        },


        fetchData() {
            if (this.loading) return;
            this.loading = true;
            const params = this.extractParams(); // 格式化查询参数

            this.$axios({
                url: "api/giftActivity/queryGiftActivityList",
                params
            })
                .then(res => {
                    if (res.code === 2000 && res.data.giftActList) {

                        this.tableData = res.data.giftActList || []
                        this.total = res.data?.count;

                        this.loading = false;

                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    console.warn("获取数据失败", reason);
                    this.$message({
                        message: reason.msg || "获取数据失败",
                        type: "warning"
                    });
                    this.loading = false;
                })
        },

        clearSP_trim() {
            this.SP_.searchType = 1
        },

        // 批量操作：删除
        handleBulkDelete() {
            const { checkedlist } = this;
            this.$confirm(`您确定要删除${checkedlist.length}个优惠么?`, "温馨提示")
                .then(() => {
                    this.loading = true;
                    return this.$axios({
                        url: "api/custCoupon/deleteCustCoupon",
                        method: "post",
                        data: { custCouponIds: checkedlist.map(it => it.id).join() }
                    })
                })
                .then(res => {
                    this.loading = false
                    if (res.code === 2000) {
                        this.$message({
                            message: "删除成功",
                            type: "success"
                        })
                        this.checkedlist.splice(0, this.checkedlist.length)
                        this.fetchData();
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.loading = false
                    if (reason !== "cancel") {
                        this.$message({
                            message: reason.msg || "删除失败",
                            type: "error"
                        })
                    }
                })
        },

        // 点击一键作废
        handleTotalDelete() {
            const data = this.extractParams(true);
            this.$confirm(
                "请精准的筛选条件进行查询劵进行作废",
                "温馨提示",
                {
                    confirmButtonText: '已选择',
                    cancelButtonText: '取消',
                }
            )
                .then(() => {
                    this.loading = true;
                    return this.fetchCount(data)
                })
                .then(count => {
                    this.loading = false;
                    return this.$confirm(
                        `满足条件的优惠共有${count}张, 确定都需要作废么?`,
                        "温馨提示",
                        {
                            confirmButtonText: '确定作废',
                            cancelButtonText: '取消',
                        }
                    )
                })
                .then(() => {
                    this.loading = true;
                    return this.totalDelete(data)
                })
                .then(() => {
                    this.loading = false;
                    this.$message({
                        message: "作废成功",
                        type: "success"
                    })
                    this.fetchData();
                })
                .catch(reason => {
                    if (reason === "cancel") {
                        console.log("用户取消")
                    } else {
                        this.$message({
                            message: reason.msg || "作废失败",
                            type: "error"
                        })
                    }
                    this.loading = false
                })
        },
        // 点击同步员工code
        handleCusCode() {
            // const data = this.extractParams(true);
            this.$confirm(
                "确定同步员工code？",
                "温馨提示",
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }
            )

                .then(() => {
                    this.loading = true;
                    return this.updateCustCouponSaleCode()
                })
                .then(() => {
                    this.loading = false;
                    this.$message({
                        message: "同步成功",
                        type: "success"
                    })
                    this.fetchData();
                })
                .catch(reason => {
                    if (reason === "cancel") {
                        console.log("用户取消")
                    } else {
                        this.$message({
                            message: reason.msg || "同步失败",
                            type: "error"
                        })
                    }
                    this.loading = false
                })
        },


        fetchCount(data) {
            return this.$axios({
                url: "api/custCoupon/queryBatchDeleteCount", method: "post",
                data
            })
                .then(res => {
                    if (res.code === 2000) {
                        return res.data.count;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
        },

        updateCustCouponSaleCode() {
            return this.$axios({
                url: "api/custCoupon/updateCustCouponSaleCode", method: "post",

            })
                .then(res => {
                    if (res.code === 2000) {
                        return res
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
        },

        totalDelete(data) {
            return this.$axios({
                url: "api/custCoupon/batchDeleteCustCoupon", method: "post",
                data
            })
                .then(res => {
                    if (res.code === 2000) {
                        return res
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
        },


        // 点击导出
        handleExportBtn() {
            // 检查有无时间条件，没有的话不能导出
            // const {startTime, endTime} = this.SP_;
            // if (!(startTime || endTime)) {
            //     return this.$message({
            //         message: "请选择导出的时间范围",
            //         type: "error"
            //     });
            // }

            this.$confirm("确认导出当前条件下的优惠券数据吗？")

                .then(() => {
                    this.posting = true
                    // const params = {
                    //     ...this.extractParams(true),
                    //     // startTime,
                    //     // endTime
                    // };
                    return this.$axios({
                        url: "api/custCoupon/downCustCoupon",
                        method: "post",
                        data: {
                            // content: JSON.stringify(params)
                            ...this.extractParams(true)
                        }
                    })
                })

                .then(res => {
                    if (res.code === 2000) {
                        this.$message({
                            message: "已加入导出任务，去消息管理的下载通知中查看吧",
                            type: "success"
                        })
                    } else if (res.code !== 1003) return Promise.reject(res)
                })

                .catch(reason => {
                    // this.$confirm拒绝结果是值 cancel
                    if (reason === "cancel") {
                        console.log("取消了")
                    } else {
                        this.$message({
                            message: reason.msg || "导出失败",
                            type: "error"
                        });
                        console.warn("导出失败", reason);
                    }
                })
                .then(() => this.posting = false)
        }
    },

    created() {
        this.auth.add = this.$hasAuthFor("api/giftActivity/addGiftActivity");
        this.auth.delete = this.$hasAuthFor("api/giftActivity/deleteGiftActivity");
        this.auth.edit = this.$hasAuthFor("api/giftActivity/editGiftActivity");




        this.auth.export = this.$hasAuthFor("api/custCoupon/downCustCoupon");
        this.auth.batchDelete = this.$hasAuthFor("api/custCoupon/deleteCustCoupon");
        this.auth.updateCustCouponSaleCode = this.$hasAuthFor("api/custCoupon/updateCustCouponSaleCode");
        this.auth.totalDelete = this.$hasAuthFor("api/custCoupon/batchDeleteCustCoupon") &&
            this.$hasAuthFor("api/custCoupon/queryBatchDeleteCount");
    }
};